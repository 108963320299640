var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CrudMultiSelect',{attrs:{"value":_vm.value,"endpoint":'/data-api/job-status-description',"searchFields":[
    { field: 'jobStatus', init: true },
    'jobStatusDescriptionLangs.description',
    'jobStatusDescriptionLangs.nameBrand',
    'jobStatusDescriptionLangs.namePartner',
    'jobStatusDescriptionLangs.nameCustomer',
    'jobStatusDescriptionLangs.nameGid',
  ],"trackBy":'value',"label":'label',"transformValue":({ jobStatus, jobStatusDescriptionLangs }) => ({
      value: jobStatus,
      label: jobStatusDescriptionLangs[0].nameGid,
    }),"partial":false,"placeholder":'Job Status',"multiple":true,"initFetchLabels":true},on:{"input":function($event){return _vm.$emit('input', $event)}}})
}
var staticRenderFns = []

export { render, staticRenderFns }