
/* eslint-disable no-unused-vars */

import axios from 'axios';
import moment from 'moment';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import DatePicker from '@gid/vue-common/components/DatePicker.vue';
import CrudListPagination from '@gid/vue-common/components/crud-list/CrudListPagination.vue';
import CrudListTable from '@gid/vue-common/components/crud-list/CrudListTable.vue';
import CrudListErrorMessage from '@gid/vue-common/components/crud-list/CrudListErrorMessage.vue';
import JobSelector from './JobSelector.vue';
import AccountSelector from './AccountSelector.vue';
import JobStatusSelector from './JobStatusSelector.vue';
import {
  JobView,
  AccountingDocument,
  Account,
  JobStatusEnum,
  AccountingDocumentTargetEnum,
  AccountingDocumentStatusEnum,
  Job,
} from '@gid/models';
import {
  AccountingRecheckDraftsModule,
  AccountingRecheckDraftsActionsEnum,
  DraftFiltersAccountOption,
  DraftFiltersJobOption,
} from '../../store/accounting-recheck-drafts.module';
import {
  CreateQueryParams,
  QueryFilterArr,
  QueryFilter,
  RequestQueryBuilder,
  SFields,
  SConditionAND,
} from '@dataui/crud-request';

import { getModule } from 'vuex-module-decorators';

@Component({
  components: {
    DatePicker,
    CrudListPagination,
    CrudListTable,
    CrudListErrorMessage,
    JobSelector,
    AccountSelector,
    JobStatusSelector,
  },
})
export default class DraftInvoices extends Vue {
  brandLoading: boolean = false;
  partnerLoading: boolean = false;
  customerLoading: boolean = false;
  jobLoading: boolean = false;
  brands: DraftFiltersAccountOption[] = [];
  partners: DraftFiltersAccountOption[] = [];
  customers: DraftFiltersAccountOption[] = [];
  jobs: DraftFiltersJobOption[] = [];

  inProgress: boolean = false;

  createAction: any = { name: 'create' };
  uploadDocumentFile: any = null;
  uploadDocumentNumber: string = '';

  // Computed
  get store(): AccountingRecheckDraftsModule {
    return getModule(AccountingRecheckDraftsModule, this.$store);
  }
  get columns() {
    return [
      {
        key: 'number',
        sortable: true,
        label: this.$t('accounting.common.document-number'),
      },
      {
        key: 'targetName',
        sortable: true,
        label: this.$t('accounting.common.target-name'),
      },
      {
        key: 'totalAmount',
        sortable: true,
        label: this.$t('accounting.common.total-amount'),
      },
      {
        key: 'type',
        sortable: true,
        label: this.$t('accounting.common.document-type'),
      },
      { key: 'status', label: this.$t('accounting.common.document-status') },
    ];
  }

  get uploadDisabled() {
    return this.store.selectedCount !== 1;
  }

  get submitDisabled() {
    switch (this.createAction?.name) {
      case 'create':
        return !(this.store.selectedCount >= 1);
      case 'upload':
        return (
          this.uploadDisabled ||
          !this.uploadDocumentFile ||
          !this.uploadDocumentNumber?.length
        );
      default:
        return true;
    }
  }

  get createOptions() {
    return [
      { name: 'create' },
      { name: 'upload', $isDisabled: this.uploadDisabled },
    ];
  }

  @Watch('uploadDisabled')
  createOptionsChanged(value) {
    if (this.uploadDisabled && this.createAction?.name === 'upload') {
      this.createAction = { name: 'create' };
    }
  }

  refreshJobList() {
    const search: Array<SFields | SConditionAND> = [];

    if (this.store.filters.filterType === 'dateRange') {
      if (this.store.filters.dateFrom) {
        search.push({
          'JobView.deliveryDate': {
            $gte: this.store.filters.dateFrom,
          },
        });
      }
      if (this.store.filters.dateTo) {
        search.push({
          'JobView.deliveryDate': {
            $lte: this.store.filters.dateTo,
          },
        });
      }
      if (this.store.filters.jobStatuses?.length > 0) {
        search.push({
          'JobView.status': {
            $in: this.store.filters.jobStatuses.map(({ value }) => value),
          },
        });
      }
    }

    if (this.store.filters.filterType === 'jobList') {
      if (this.store.filters.selectedJobs?.length) {
        search.push({
          'JobView.id': {
            $in: this.store.filters.selectedJobs.map((j) => j.id),
          },
        });
      }
    }

    const documentTarget: AccountingDocumentTargetEnum[] = [];
    const brandVariant = this.store.filters.targetBrandVariant;
    const customerVariant = this.store.filters.targetCustomerVariant;
    if (this.store.filters.targetBrand) {
      if (brandVariant === 'any' || brandVariant === 'brand') {
        documentTarget.push(AccountingDocumentTargetEnum.BRAND);
      }
      if (brandVariant === 'any' || brandVariant === 'brand_1') {
        documentTarget.push(AccountingDocumentTargetEnum.BRAND_1);
      }
      if (brandVariant === 'any' || brandVariant === 'brand_2') {
        documentTarget.push(AccountingDocumentTargetEnum.BRAND_2);
      }
    }
    if (this.store.filters.targetPartner) {
      documentTarget.push(AccountingDocumentTargetEnum.PARTNER);
    }
    if (this.store.filters.targetCustomer) {
      if (customerVariant === 'any' || customerVariant === 'customer') {
        documentTarget.push(AccountingDocumentTargetEnum.CUSTOMER);
      }
      if (customerVariant === 'any' || customerVariant === 'customer_second') {
        documentTarget.push(AccountingDocumentTargetEnum.CUSTOMER_2);
      }
    }
    if (this.store.filters.targetSupplier) {
      documentTarget.push(AccountingDocumentTargetEnum.SUPPLIER);
    }
    if (this.store.filters.targetAffiliate) {
      documentTarget.push(AccountingDocumentTargetEnum.AFFILIATE);
    }
    if (documentTarget.length) {
      search.push({
        documentTarget: {
          $in: documentTarget,
        },
      });
    }
    if (
      this.store.filters.targetBrand &&
      this.store.filters.selectedBrands.length
    ) {
      const brandIds = this.store.filters.selectedBrands.map(
        (brand) => brand.id,
      );
      if (brandVariant === 'any') {
        search.push({
          $or: [
            {
              'brand.id': {
                $in: brandIds,
              },
            },
            {
              'brand_1.id': {
                $in: brandIds,
              },
            },
            {
              'brand_2.id': {
                $in: brandIds,
              },
            },
          ],
        });
      } else {
        search.push({
          [`${brandVariant}.id`]: {
            $in: brandIds,
          },
        });
      }
    }
    if (
      this.store.filters.targetPartner &&
      this.store.filters.selectedPartners.length
    ) {
      search.push({
        'partner.id': {
          $in: this.store.filters.selectedPartners.map((partner) => partner.id),
        },
      });
    }
    if (
      this.store.filters.targetCustomer &&
      this.store.filters.selectedCustomers.length
    ) {
      const customerIds = this.store.filters.selectedCustomers.map(
        (customer) => customer.id,
      );
      if (customerVariant === 'any') {
        search.push({
          $or: [
            {
              'customer.id': {
                $in: customerIds,
              },
            },
            {
              'customer_2.id': {
                $in: customerIds,
              },
            },
          ],
        });
      } else {
        search.push({
          [`${customerVariant}.id`]: {
            $in: this.store.filters.selectedCustomers.map(
              (customer) => customer.id,
            ),
          },
        });
      }
    }
    if (
      this.store.filters.targetAffiliate &&
      this.store.filters.selectedAffiliates.length
    ) {
      search.push({
        'affiliate.id': {
          $in: this.store.filters.selectedAffiliates.map(
            (affiliate) => affiliate.id,
          ),
        },
      });
    }
    if (
      this.store.filters.targetSupplier &&
      this.store.filters.selectedSuppliers.length
    ) {
      search.push({
        'products.supplierSfid': {
          $in: this.store.filters.selectedSuppliers.map(
            (supplier) => supplier.id,
          ),
        },
      });
    }
    const query: CreateQueryParams = {
      page: this.store.pagination.currentPage,
      limit: this.store.pagination.perPage,
      search: {
        $and: search,
      },
    };
    this.store[AccountingRecheckDraftsActionsEnum.ITEMS_FETCH]({
      query,
      noPagination: true,
    });
    this.store.selectionSetAll(false);
  }

  submit() {
    this.store.clearLastError();
    let p;
    switch (this.createAction?.name) {
      case 'create':
        // p = this.createDocuments();
        break;
      case 'upload':
        // p = this.uploadDocument();
        break;
      default:
        return;
    }
    return p.then(() => {
      if (!this.store.error) {
        this.refreshJobList();
      }
    });
  }
}
